/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"syntax"
;

@import "bootstrap/scss/bootstrap";

body {
	margin-top: 56px;
}

.blumenaujs {
	font-family: 'Passion One', cursive;
	font-size: 25px;
	font-weight: 300;
	text-transform: uppercase;
	margin: 0;
	line-height: 1.2;
}


#projetos {
    &.row-space {
        @media (max-width:992px) {
            margin: 0;
        }
    }
    i {
        @media (max-width:992px) {

            margin-right: 0;
        }
    }
    .service {
        @media (max-width:992px) {
            text-align: center;
            padding-bottom: 50px;
        }
        h4 {
            @media (max-width:992px) {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
}

.mediumnavigation {
	h1 {
		font-family: 'Passion One', cursive;
		font-size: 25px;
		font-weight: 300;
		text-transform: uppercase;
		margin: 0;
	}
	.nav-item {
		&--icons{
			.nav-link{
				padding-right: 0;
			}
			i {
				font-size: 1.4em;
			}
		}
	}
}

.header-banner {
	color: #fff;
    padding: 50px 0 60px;
    text-align: center;
    background-color: #1F4665;
	background-image: linear-gradient(#1e415e 10%,#2b5b84 90%);

	.header-logo {
		width: 300px;
		margin-bottom: 30px;
	}

	.intro-text {
		text-align: center;
		margin: 0 auto;
		width: 300px;
	}
}

.section-header {
	padding-bottom: 50px;

	h2 {
		line-height: 20px;
		margin: 0;
		font-size: 36px;
		font-weight: 300;
		margin-bottom: 20px;
		margin-top: 20px;
		color: #666;
		border: 0;
        @media (max-width:992px) {
            line-height: inherit;
        }
	}

	hr {
		height: 2px;
		width: 60px;
		background: #1C3B56;
		position: relative;
		border: 0;
		margin: 30px 0 0 0;
        @media (max-width:992px) {
            width: 100%;
        }
    }
}

.projects {
	h4 {
		color: #666;
    font-size: 20px;
    border-bottom: 1px solid #1C3B56;
    padding-bottom: 20px;
		display: inline-block;
	}
	i {
		font-size: 36px;
    margin-right: 20px;
    color: #1C3B56;
    padding-bottom: 10px;
    display: block;
	}
}

.row-space {
	margin: 40px 0;
}

.footer {
	color: #fff;
	a {
		color: $navbar-dark-color;
		@include hover-focus {
      color: $navbar-dark-brand-hover-color;
    }
	}

	.mediumnavigation {
		border: 0;
		box-shadow: none;
	}

	&--copyright{
		margin-top:10px;
	}
}

.comunity{

	&--members{
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}
}

#grupybnu-root{
	.mediumnavigation {
		.navbar-light {
			.navbar-brand {
				color: #fff !important;

				// @include hover-focus {
				// 	color: $navbar-light-active-color;
				// }
			}
		}
	}
}